//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      dislogSet: !1,
      goodsList: [],
      page: 1,
      rows: 10,
      total_number: 0,
      goods_name: '',
      goods_coding: '',
      classify_id: [],
      classify_ids: [],
      currentGoodsInfo: {},
      groupInfo: [],
      distribution_commission: '',
      is_show: 0,
      picture: '',
      type: 1,
      batch_distribution_commission: '',
      classifyLis: [],
    };
  },
  computed: {
    goodsClass() {
      let goodsTypeList = this.$store.getters.goodsTypeList;
      return goodsTypeList;
    },
  },
  created() {
    this.$store.dispatch('goods/getTypeList');
    this.getList();
    this.getClassifyList();
  },
  methods: {
    //获取平台商品分类
    getClassifyList() {
      this.$axios.post(this.$api.distribution.classifyList).then(res => {
        if (res.code == 0) {
          let list = res.result;
          for (let i in list) {
            let children_id = [];
            if (list[i]._child) {
              for (let y in list[i]._child) {
                children_id.push(list[i]._child[y].id);
              }
              list[i].children_id = children_id;
            }
          }
          this.classifyLis = list;
        }
      });
    },
    handleClassChange(val) {
      let arr = [];
      for (let i in val) {
        for (let y in val[i]) {
          arr.push(val[i][y]);
        }
      }
      arr = [...new Set(arr)];
      this.goodsForm.classify_id = arr.join();
    },
    batchPut() {
      this.groupInfo.map(item => {
        if (this.batch_distribution_commission) {
          item.value = this.batch_distribution_commission;
        }
      });
    },
    shareSet() {
      this.$axios
        .post(this.$api.distribution.editShareSetInfo, {
          is_show: this.is_show,
          picture: this.picture,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    searchClear() {
      this.goods_name = '';
      this.goods_coding = '';
      this.classify_id = '';
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    getList() {
      let classify_id = this.classify_id;
      if (classify_id.length) {
        if (classify_id.length > 1) {
          classify_id = classify_id[1];
        } else {
          classify_id = classify_id[0];
        }
      }
      this.$axios
        .post(this.$api.distribution.goodsList, {
          status: 1,
          page: this.page,
          rows: this.rows,
          goods_name: this.goods_name,
          goods_coding: this.goods_coding,
          classify_id: classify_id,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            this.goodsList = list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    set(row) {
      this.distribution_commission = '';
      this.batch_distribution_commission = '';
      this.classify_ids = [];
      this.$axios
        .post(this.$api.distribution.goodsDetail, {
          goods_id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            let goodsClass = this.classifyLis;
            this.dislogSet = !0;
            this.currentGoodsInfo = row;
            this.type = res.result.type || 1;
            if (!Array.isArray(res.result) && res.result.classify_id) {
              let classify_id = res.result.classify_id.split(',').map(Number);
              let classify_id_arr = [];
              let classify_id_arr_arr = [];
              let classify_obj = [];
              for (let i in goodsClass) {
                for (let y in classify_id) {
                  if (goodsClass[i].id == classify_id[y]) {
                    classify_id_arr.push(goodsClass[i].id);
                    classify_id.splice(y, 1);
                  }
                }
              }
              classify_id_arr = [...new Set(classify_id_arr)];
              for (let i in goodsClass) {
                for (let y in classify_id_arr) {
                  if (goodsClass[i].id == classify_id_arr[y]) {
                    let children_id = goodsClass[i].children_id || [];
                    if (children_id.length > 0) {
                      for (let k in classify_id) {
                        let index = children_id.indexOf(classify_id[k]);
                        if (index > -1) {
                          classify_obj.push({
                            key: y,
                            id: children_id[index],
                            parent_id: classify_id_arr[y],
                          });
                        }
                      }
                    }
                  }
                }
              }
              for (let i in classify_id_arr) {
                classify_id_arr_arr.push([classify_id_arr[i]]);
              }
              for (let i in classify_obj) {
                classify_id_arr_arr[classify_obj[i].key].push(classify_obj[i].id);
                classify_id_arr_arr[classify_obj[i].key].push(classify_obj[i].parent_id);
              }
              for (let i in classify_id_arr_arr) {
                let len = classify_id_arr_arr[i].length;
                if (len > 1) {
                  classify_id_arr_arr[i].splice(classify_id_arr_arr[i].length - 1, 1);
                }
              }
              for (let i in classify_id_arr_arr) {
                let len = classify_id_arr_arr[i].length;
                if (len > 2) {
                  for (let y = 0; y < len; y += 2) {
                    classify_id_arr_arr.push(classify_id_arr_arr[i].slice(y, y + 2));
                  }
                  classify_id_arr_arr.splice(i, 1);
                }
              }
              this.classify_ids = classify_id_arr_arr;
            }
            if (row.is_spec) {
              for (let i in res.result.setting) {
                res.result.setting[i].value = res.result.setting[i].value || '';
              }
              this.groupInfo = res.result.setting;
            } else {
              if (row.is_set) {
                this.distribution_commission = res.result.setting.value;
              }
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    cancel(row) {
      this.$confirm('确认是否取消该商品分销？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.distribution.goodsCancel, {
            goods_id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_set = 0;
              this.$message.success('已取消');
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    comefromSet() {
      let classify_ids = [];
      if (this.classify_ids.length) {
        for (let i in this.classify_ids) {
          for (let y in this.classify_ids[i]) {
            classify_ids.push(this.classify_ids[i][y]);
          }
        }
        classify_ids = [...new Set(classify_ids)];
        classify_ids = classify_ids.join();
      } else {
        return this.$message.warning('请选择平台商品分类');
      }
      if (this.currentGoodsInfo.is_spec) {
        for (let i in this.groupInfo) {
          if (!this.groupInfo[i].value) return this.$message.warning('请填写完所有规格分销佣金');
        }
        if (this.type == 1) {
          for (let i in this.groupInfo) {
            if (Number(this.groupInfo[i].value) >= Number(this.groupInfo[i].price)) return this.$message.warning('分销佣金不能大于等于商品价格');
          }
        } else {
          for (let i in this.groupInfo) {
            if (Number(this.groupInfo[i].value) > 100) return this.$message.warning('分销佣金比例不能大于100%');
          }
        }
        this.$axios
          .post(this.$api.distribution.goodsSet, {
            goods_id: this.currentGoodsInfo.id,
            is_spec: 1,
            type: this.type,
            classify_id: classify_ids,
            setting: this.groupInfo,
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.dislogSet = !1;
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        if (!this.distribution_commission) return this.$message.warning('请填写分销佣金');
        if (this.type == 1) {
          if (Number(this.distribution_commission) >= Number(this.currentGoodsInfo.goods_price)) return this.$message.warning('分销佣金不能大于等于商品价格');
        } else {
          if (Number(this.distribution_commission) > 100) return this.$message.warning('分销佣金比例不能大于100%');
        }
        this.$axios
          .post(this.$api.distribution.goodsSet, {
            goods_id: this.currentGoodsInfo.id,
            is_spec: 0,
            type: this.type,
            classify_id: classify_ids,
            setting: {
              price: this.currentGoodsInfo.goods_price,
              value: this.distribution_commission,
            },
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.dislogSet = !1;
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
  },
};
